import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import styles
import "../styles/blog-post.scss"

// Images
import ArrowBlue from "../images/inline-svgs/right-arrow-blue.svg"
import Twitter from "../images/inline-svgs/twitter.svg"
import Linkedin from "../images/inline-svgs/linkedin.svg"
import Email from "../images/inline-svgs/email-dark.svg"

const SuccessStoryPost = ({ data, location }) => {
  const post = data.ghostPost

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />

      <div className="post-view">
        <article
          className={
            "wrapper-800" +
            (post.feature_image == null ? " no-feature-image" : "")
          }
        >
          {/* Header */}
          <header className="post__header">
            {/* Back button */}
            <div className="post__primary-tag">
              <Link to={`/press/`} className="go-back-link">
                <span className="icon">
                  <ArrowBlue />
                </span>
                <span className="text">{post.primary_tag.name}</span>
              </Link>
            </div>

            {/* Title */}
            <h1 className="post__title">{post.title}</h1>

            <div className="post__meta">
              {/* Authors */}
              <div className="post__authors">
                <div className="avatars">
                  {post.authors.map(author => (
                    <Link
                      to={`/author/${author.slug}/`}
                      key={author.slug}
                      className="avatar"
                    >
                      <img src={author.profile_image} alt={author.name} />
                    </Link>
                  ))}
                </div>
                <div className="names">
                  {post.authors.map((author, index) => (
                    <Link to={`/author/${author.slug}/`} key={author.slug}>
                      {index >= 1 ? ", " : ""}
                      {author.name}
                    </Link>
                  ))}
                </div>
              </div>

              <div className="middot">&middot;</div>

              {/* Tags */}
              <div className="post__tags">
                {post.tags.map((tag, index) => {
                  if (index == 0 || tag.visibility === "internal") return
                  return (
                    <div key={tag.slug}>
                      <Link to={`/tag/${tag.slug}/`}>{tag.name}</Link>
                      <div className="middot">&middot;</div>
                    </div>
                  )
                })}
              </div>

              {/* Date */}
              <div className="post__date">{post.published_at_pretty}</div>
            </div>
          </header>

          {/* Post Content */}
          <div className="post__body">
            <div
              className="load-external-scripts"
              dangerouslySetInnerHTML={{ __html: post.codeinjection_head }}
            ></div>
            <div
              className="post-full-content load-external-scripts"
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></div>
          </div>

          {/* Discourse comments */}
          <div>
            <br />
            <br />
          </div>
          <div id="discourse-comments"></div>

          {/* Post Sharing */}
          <div className="post__sharing">
            <div className="post__sharing-title">Share</div>
            <div className="post__sharing-icons">
              <button
                className="post__sharing-icon"
                onClick={() => {
                  window.open(
                    `https://twitter.com/share?text=${post.title}&amp;url=${window.location.href}`,
                    "share-twitter",
                    "width=550,height=235"
                  )
                  return false
                }}
              >
                <span className="icon icon--twitter">
                  <Twitter />
                </span>
              </button>

              <button
                className="post__sharing-icon"
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${post.title}&summary=${post.excerpt}`,
                    "share-linkedin",
                    "width=550,height=235"
                  )
                  return false
                }}
              >
                <span className="icon icon--linkedin">
                  <Linkedin />
                </span>
              </button>

              <button
                className="post__sharing-icon post__sharing-icon--email"
                onClick={() => {
                  window.location.href = `mailto:?subject=${post.title}&amp;body=Check out this site ${window.location.href}`
                }}
                title="share by email"
              >
                <span className="icon icon--email">
                  <Email />
                </span>
              </button>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default SuccessStoryPost

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`
